const styles = {
  colors: {
    primary: '#34A08E',
    background: '#000000',
    shape: `#000000`,
    title: `#C63E6C`,
    text: `#FFFFFF`,
    components: {
      blockquote: {
        background: `#E6FFFA`,
        text: `#285E61`,
      },
    },
  },
  fonts: {
    fontFace: {
      family: 'system-ui',
    },
    body: 'system-ui',
  },
};

export default styles;
